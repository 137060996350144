import React, { useCallback } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { Button, Icon } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesktopExperience, MobileExperience } from '../../../components/Experiences';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { text } from '../../../utils/localization';
import { QuickToolItem } from '../../QuickTools/QuickToolItem';
import { ACTION_NAME, PageSection, PageStage, QUAD_TRACKING_EVENTS } from '../../TrackingEvents/constant';
import { decorationAreaStore } from '../store';

export const DecorationAreaButton = observer(({ className }: React.HTMLAttributes<HTMLDivElement>) => {
    const { productData } = useProductConfiguration();
    const { fireEvent } = useCustomEventPropagation();
    const { decorationAreaAttribute, isDecorationAreaFlexible, setOpenDecorationAreaModal, setActiveDecorationArea } =
        decorationAreaStore;

    const handleChangeAreaClick = useCallback(() => {
        if (!decorationAreaAttribute) {
            return;
        }

        const activeDecorationArea = productData?.selectedOptions[decorationAreaAttribute] ?? '';

        setActiveDecorationArea(activeDecorationArea);
        setOpenDecorationAreaModal(true);

        fireEvent({
            name: ACTION_NAME.EXPERIMENT_CLICKED,
            extraData: {
                label: QUAD_TRACKING_EVENTS.CLICK_CHANGE_AREA,
                eventDetail: 'QUAD;QUAD;Change area',
                navigationDetail: 'ChangeArea:Change area button',
                pageSection: PageSection.ChangeArea,
                pageStage: PageStage.Design,
                category: undefined,
            },
        });
    }, [productData?.selectedOptions, decorationAreaAttribute]);

    if (!isDecorationAreaFlexible) {
        return null;
    }

    return (
        <>
            <DesktopExperience>
                <Button mr="2" px="6" onClick={handleChangeAreaClick} className={className}>
                    {text('changeArea')}
                </Button>
            </DesktopExperience>
            <MobileExperience>
                <QuickToolItem
                    Icon={<Icon iconType="printedProducts" />}
                    buttonLabel={text('changeArea')}
                    onClick={handleChangeAreaClick}
                />
            </MobileExperience>
        </>
    );
});
