import React from 'react';
import { Button, Icon } from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesktopExperience, MobileExperience } from '../../../components/Experiences';
import { ProductAttributes } from '../../../config/constant';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { text } from '../../../utils/localization';
import { QuickToolItem } from '../../QuickTools/QuickToolItem';
import { useAutoRecognitionTracking } from '../hooks';
import { changeSizeStore } from '../store';

export const ChangeSizeButton = observer(({ className }: React.HTMLAttributes<HTMLDivElement>) => {
    const { productData } = useProductConfiguration();
    const { trackOpenChangeSizeModal } = useAutoRecognitionTracking();
    const {
        sizeAttribute,
        isSizeFlexible,
        setOpenChangeSizeModal,
        setActiveSize,
        setActiveOrientation,
        changeSizePrices,
    } = changeSizeStore;

    const onClick = () => {
        const selectedOrientation = productData?.selectedOptions[ProductAttributes.Orientation] ?? '';
        const selectedSize = productData?.selectedOptions[sizeAttribute] ?? '';
        setActiveSize(selectedSize);
        setActiveOrientation(selectedOrientation);
        setOpenChangeSizeModal(true);
        trackOpenChangeSizeModal({
            autoOpened: false,
            selectedOrientation,
            selectedSize,
        });
    };

    if (!isSizeFlexible || Object.keys(changeSizePrices).length < 1) {
        return null;
    }

    return (
        <>
            <DesktopExperience>
                <Button mr="2" px="5" onClick={onClick} data-testid="change-size" className={className}>
                    {text('changeSize')}
                </Button>
            </DesktopExperience>
            <MobileExperience>
                <QuickToolItem
                    Icon={<Icon iconType="printedProducts" />}
                    buttonLabel={text('changeSize')}
                    onClick={onClick}
                    dataTestId="change-size"
                />
            </MobileExperience>
        </>
    );
});
